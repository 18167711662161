import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { MainLayout } from 'src/layouts/main';

import { SplashScreen } from 'src/components/loading-screen';
import { SimpleLayout } from 'src/layouts/simple';

import { authRoutes } from './auth';
import { mainRoutes } from './main';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';

// ----------------------------------------------------------------------

const HomePage = lazy(() => import('src/pages/home'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));

export function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <SimpleLayout content={{ compact: false }}>
          <ComingSoonPage />
        </SimpleLayout>
      ),
    },
    // {
    //   path: '/',
    //   com: <Navigate to='/coming-soon' replace />
    //   // TODO: uncomment for dev
    //   // element: (
    //   //   <Suspense fallback={<SplashScreen />}>
    //   //     <MainLayout>
    //   //       <HomePage />
    //   //       {/* TODO show comingsoon */}
    //   //     </MainLayout>
    //   //   </Suspense>
    //   // ),
    // },

    // Auth
    ...authRoutes,
    ...authDemoRoutes,

    // Dashboard
    ...dashboardRoutes,

    // Main
    ...mainRoutes,

    // Components
    ...componentsRoutes,

    // No match
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
