import { useId, forwardRef } from 'react';

import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

import { logoClasses } from './classes';

// ----------------------------------------------------------------------

export const Logo = forwardRef(
  ({ width = 40, height = 40, disableLink = false, className, href = '/', sx, ...other }, ref) => {
    const theme = useTheme();

    const gradientId = useId();

    const PRIMARY_LIGHT = theme.vars.palette.primary.light;

    const PRIMARY_MAIN = theme.vars.palette.primary.main;

    const PRIMARY_DARK = theme.vars.palette.primary.dark;

    /*
     * OR using local (public folder)
     * const logo = ( <Box alt="logo" component="img" src={`${CONFIG.site.basePath}/logo/logo-single.svg`} width={width} height={height} /> );
     */

    const logo = (
      <svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 512 512"
>
  <path
    style={{ fill: "#ED2F49" }}
    d="M312.364,8.17c-3.134,0-6.208,0.239-9.212,0.69l-46.127,244.655l105.861-142.203
  c8.031-10.786,12.797-24.221,12.797-38.79C375.683,36.982,347.334,8.17,312.364,8.17z"
  />
  <path
    style={{ fill: "#FE7074" }}
    d="M209.476,8.86c-3.028-0.451-6.13-0.69-9.291-0.69c-35.272,0-63.866,28.811-63.866,64.352
  c0,14.569,4.807,28.003,12.907,38.79l106.776,142.203L209.476,8.86z"
  />
  <path
    style={{ fill: "#FF455E" }}
    d="M341.781,72.522c0-32.351-16.778-59.116-38.629-63.662c-20.135,3.023-36.989,15.814-46.127,33.496
  c-9.138-17.681-25.992-30.473-46.127-33.496c-21.849,4.546-38.629,31.312-38.629,63.662c0,14.569,3.404,28.003,9.141,38.79
  l75.615,142.203l75.615-142.203C338.376,100.525,341.781,87.09,341.781,72.522z"
  />
  <path
    style={{ fill: "#FF9B5F" }}
    d="M96.982,110.722c-13.136,0-23.783,10.649-23.783,23.783v156.064h47.567V134.505
  C120.764,121.369,110.116,110.722,96.982,110.722z"
  />
  <path
    style={{ fill: "#FEBB8E" }}
    d="M88.084,268.783V134.505c0-13.136-10.649-23.783-23.784-23.783s-23.783,10.649-23.783,23.783
  l0.133,149.944c0.004,5.428,2.185,13.74,4.845,18.471l94.211,152.563c2.66,4.731,4.836,13.044,4.836,18.471v29.876h95.119v-51.2
  l-39.871-97.498L88.084,268.783z"
  />
  <path
    style={{ fill: "#FCD5A2" }}
    d="M239.662,392.548c0-16.284-9.421-39.027-20.934-50.542l-90.042-90.042
  c-9.288-9.288-24.347-9.288-33.635,0c-9.288,9.288-9.288,24.347,0,33.635l72.683,72.683v21.36c0,40.31,31.619,72.987,71.928,72.987
  L239.662,392.548L239.662,392.548z"
  />
  <path
    style={{ fill: "#FF9B5F" }}
    d="M415.021,110.722c13.136,0,23.783,10.649,23.783,23.783v156.064h-47.567V134.505
  C391.238,121.369,401.886,110.722,415.021,110.722z"
  />
  <path
    style={{ fill: "#FEBB8E" }}
    d="M423.919,268.783V134.505c0-13.136,10.648-23.783,23.784-23.783
  c13.136,0,23.783,10.649,23.783,23.783l-0.133,149.944c-0.004,5.428-2.185,13.74-4.845,18.471l-94.211,152.563
  c-2.66,4.731-4.837,13.044-4.837,18.471v29.876H272.34v-51.2l39.871-97.498L423.919,268.783z"
  />
  <path
    style={{ fill: "#FCD5A2" }}
    d="M272.34,392.548c0-16.284,9.421-39.027,20.934-50.542l90.042-90.042
  c9.288-9.288,24.347-9.288,33.635,0c9.288,9.288,9.288,24.347,0,33.635l-72.683,72.683v21.36c0,40.31-31.619,72.987-71.928,72.987
  L272.34,392.548L272.34,392.548z"
  />
  <g>
    <path
      style={{ fill: "#1D2328" }}
      d="M134.462,246.187c-10.334-10.334-26.04-12.088-38.208-5.277V134.505
    c0-5.404-1.354-10.497-3.732-14.965c1.431-0.426,2.93-0.649,4.459-0.649c8.609,0,15.613,7.004,15.613,15.613v78.553
    c0,4.512,3.658,8.17,8.17,8.17c4.512,0,8.17-3.658,8.17-8.17v-78.553c0-17.619-14.335-31.953-31.953-31.953
    c-5.828,0-11.447,1.574-16.351,4.502c-4.782-2.855-10.366-4.502-16.33-4.502c-17.619,0-31.954,14.334-31.954,31.961l0.133,149.944
    c0.005,6.842,2.539,16.502,5.892,22.467c0.054,0.098,0.112,0.194,0.171,0.29l94.113,152.403c1.954,3.564,3.716,10.342,3.716,14.338
    v29.877c0,4.512,3.658,8.17,8.17,8.17s8.17-3.658,8.17-8.17v-29.876c0-6.846-2.53-16.507-5.884-22.475
    c-0.056-0.098-0.112-0.194-0.171-0.29L52.544,298.788c-1.955-3.565-3.721-10.348-3.725-14.346l-0.133-149.936
    c0-8.609,7.005-15.613,15.614-15.613s15.613,7.004,15.613,15.613v134.277c0,0.192,0.015,0.38,0.028,0.569
    c0.141,7.992,3.245,15.941,9.33,22.026l73.18,73.179c3.191,3.191,8.364,3.191,11.554,0c3.191-3.191,3.191-8.363,0-11.554
    l-73.18-73.179c-6.087-6.087-6.087-15.993,0-22.08c6.087-6.087,15.993-6.087,22.081,0l90.042,90.042
    c10.05,10.05,18.542,30.55,18.542,44.764v111.28c0,4.512,3.658,8.17,8.17,8.17c4.512,0,8.17-3.658,8.17-8.17V392.548
    c0-18.5-10.247-43.238-23.329-56.319L134.462,246.187z"
    />
    <path
      style={{ fill: "#1D2328" }}
      d="M447.703,102.551c-5.963,0-11.547,1.646-16.329,4.502c-4.903-2.928-10.523-4.502-16.351-4.502
    c-17.619,0-31.953,14.334-31.953,31.953v78.553c0,4.512,3.658,8.17,8.17,8.17c4.512,0,8.17-3.658,8.17-8.17v-78.553
    c0-8.609,7.005-15.613,15.613-15.613c1.528,0,3.027,0.223,4.459,0.649c-2.378,4.467-3.732,9.56-3.732,14.965v106.406
    c-12.167-6.81-27.873-5.057-38.208,5.278l-90.042,90.042c-13.081,13.081-23.329,37.819-23.329,56.319V503.83
    c0,4.512,3.658,8.17,8.17,8.17s8.17-3.658,8.17-8.17V392.548c0-14.214,8.492-34.714,18.542-44.764l90.042-90.042
    c6.087-6.087,15.993-6.087,22.081,0c6.087,6.087,6.087,15.993,0,22.081l-73.18,73.179c-3.191,3.191-3.191,8.363,0,11.554
    s8.364,3.191,11.554,0l73.18-73.179c6.085-6.085,9.189-14.034,9.33-22.026c0.013-0.188,0.028-0.377,0.028-0.569V134.505
    c0-8.609,7.005-15.613,15.613-15.613c8.608,0,15.614,7.004,15.614,15.606l-0.133,149.944c-0.003,3.998-1.77,10.78-3.725,14.346
    l-94.113,152.403c-0.059,0.095-0.115,0.192-0.171,0.29c-3.354,5.968-5.884,15.63-5.884,22.475v29.875c0,4.512,3.658,8.17,8.17,8.17
    c4.512,0,8.17-3.658,8.17-8.17v-29.876c0-3.996,1.762-10.774,3.716-14.338l94.113-152.403c0.059-0.095,0.117-0.192,0.171-0.29
    c3.353-5.966,5.887-15.625,5.892-22.467l0.133-149.951C479.656,116.885,465.321,102.551,447.703,102.551z"
    />
    <path
      style={{ fill: "#1D2328" }}
      d="M384.111,72.173C384.111,32.377,351.733,0,311.938,0c-21.953,0-42.501,10.013-56.076,26.762
    C242.723,10.452,222.591,0,200.065,0c-39.797,0-72.173,32.377-72.173,72.173c0,15.85,5.045,30.895,14.59,43.509l107.005,141.434
    c1.544,2.041,3.955,3.241,6.515,3.241c2.56,0,4.971-1.199,6.515-3.241l107.005-141.433
    C379.065,103.069,384.111,88.024,384.111,72.173z M356.49,105.824L256.001,238.645L155.512,105.823
    c-7.379-9.752-11.28-21.389-11.28-33.649c0-30.786,25.047-55.833,55.833-55.833c30.485,0,55.285,24.8,55.285,55.285
    c0,4.512,3.658,8.17,8.17,8.17c4.512,0,8.17-3.658,8.17-8.17c0-10.665-2.342-20.792-6.541-29.894
    c10.264-15.753,27.853-25.391,46.788-25.391c30.786,0,55.833,25.047,55.833,55.833C367.771,84.435,363.87,96.071,356.49,105.824z"
    />
  </g>
</svg>
    );

    return (
      <NoSsr
        fallback={
          <Box
            width={width}
            height={height}
            className={logoClasses.root.concat(className ? ` ${className}` : '')}
            sx={{
              flexShrink: 0,
              display: 'inline-flex',
              verticalAlign: 'middle',
              ...sx,
            }}
          />
        }
      >
        <Box
          ref={ref}
          component={RouterLink}
          href={href}
          width={width}
          height={height}
          className={logoClasses.root.concat(className ? ` ${className}` : '')}
          aria-label="logo"
          sx={{
            flexShrink: 0,
            display: 'inline-flex',
            verticalAlign: 'middle',
            ...(disableLink && { pointerEvents: 'none' }),
            ...sx,
          }}
          {...other}
        >
          {logo}
        </Box>
      </NoSsr>
    );
  }
);
